div.meeting {
  margin: auto;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 1%;
  border-bottom: #1E1E1E 2px solid;
  padding-top: 5px;
  padding-bottom: 1%;
}
.Date{
  width: 80%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  border: none;
  z-index: 5;
  padding-top: 5px;
}
.meeting>img {
  margin-top: 2%;
  width: 50%;
}