.abtUs {
    display: flex;
    justify-content: space-around;
    text-align: left;
    margin: auto;
    width: 70%;
    height: auto;
    padding-bottom: 1%;
    border-bottom: #1E1E1E 2px solid;
}

.addproj {
    display: flex;
    justify-content: space-around;
    text-align: center;
    margin: auto;
    margin-top: 1%;
    width: 30%;
    height: auto;
}

.abtUs>img {
    width: 25%;
    height: auto;
}

.summary {
    flex-direction: column;
    width: 90%;
}

